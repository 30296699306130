import { Controller } from "stimulus";
import { debounce, getCSRFToken, setUpdatedCsrfMetaContent } from "../../util";
import { getActiveCurrency } from "../../util/currency";

export default class extends Controller {

  static targets = [
    // Add coins page
    "searchInput",

    "highlightPills", "searchContainer",

    "defaultCoinsContainer", "searchResultsContainer", "loading",
  ];

  // We use this variable to prevent race conditions from AJAX calls
  // completing at different times.
  _ajaxCount = 0;

  async connect() {
    await this._fetchPortfolioCoinsInfo();

    this.handleSearchInput = debounce(this.handleSearchInput.bind(this), 300);
    this._updateAllStars();
  }

  handlePortfolioSelect(e) {
    this.portfolioId = e.currentTarget.dataset.value;
    this.portfolioCoinIds = JSON.parse(e.currentTarget.dataset.portfolioCoinIds);

    this._updateAllStars();
  }

  handleSearchInput() {
    const query = this.searchInputTarget.value;

    this._ajaxCount += 1;
    const ajaxCount = this._ajaxCount;

    if (!query) {
      this.highlightPillsTarget.classList.remove("tw-hidden");
      this.searchContainerTarget.classList.replace("tw-h-[506px]", "tw-h-[462px]")
      this.searchContainerTarget.classList.replace("sm:tw-h-[474px]", "sm:tw-h-[430px]");

      this.loadingTarget.classList.replace("tw-h-[506px]", "tw-h-[462px]")
      this.loadingTarget.classList.replace("sm:tw-h-[474px]", "sm:tw-h-[430px]");

      this.defaultCoinsContainerTarget.classList.remove("tw-hidden");
      this.searchResultsContainerTarget.classList.add("tw-hidden");

      this._updateAllStars();
      this.loadingTarget.classList.add("!tw-hidden");

      return;
    }

    this.loadingTarget.classList.remove("!tw-hidden");

    const params = new URLSearchParams();
    params.set("query", query);
    params.set("vs_currency", getActiveCurrency());

    const url = `/${I18n.locale}/onboarding/search?${params}`;
    fetch(url, { credentials: "same-origin" })
      .then(response => response.text())
      .then(result => {
        if (!result || this._ajaxCount !== ajaxCount) {
          return;
        }

        this.highlightPillsTarget.classList.add("tw-hidden");

        this.searchContainerTarget.classList.replace("tw-h-[462px]", "tw-h-[506px]")
        this.searchContainerTarget.classList.replace("sm:tw-h-[430px]", "sm:tw-h-[474px]");

        this.loadingTarget.classList.replace("tw-h-[462px]", "tw-h-[506px]")
        this.loadingTarget.classList.replace("sm:tw-h-[430px]", "sm:tw-h-[474px]");

        this.defaultCoinsContainerTarget.classList.add("tw-hidden");
        this.searchResultsContainerTarget.innerHTML = result;
        this.searchResultsContainerTarget.classList.remove("tw-hidden");

        this._updateAllStars();
        this.loadingTarget.classList.add("!tw-hidden");
      });
  }

  handleCoin(e) {
    const coinId = +e.currentTarget.dataset.coinId;

    if (this.portfolioCoinIds.includes(coinId)) {
      this._removeCoinFromPortfolio(coinId);
      return;
    }

    this._addCoinToPortfolio(coinId);
  }

  _removeCoinFromPortfolio(coinId) {
    Modal.danger(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/en/portfolios/${this.portfolioId}/coins/${coinId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"));
        } else {
          this._removeFromPortfolioCoins(coinId);
          this._updateAllStars();
          this._updateItemPortfolioCoinsIds();
        }
      }).catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, {
        icon: {
          classes: "fas fa-times tw-text-danger-500"
        }
      }));
    });
  }

  async _addCoinToPortfolio(coinId) {
    this.loadingTarget.classList.remove("!tw-hidden");

    await setUpdatedCsrfMetaContent();
    const url = `/en/portfolios/${this.portfolioId}/coins/${coinId}`;
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => {
      if (!response.ok) {
        Toaster.toast("An unknown error occurred", {
          icon: {
            classes: "fas fa-times tw-text-danger-500"
          }
        });
      } else {
        this.portfolioCoinIds.push(coinId);
        this._updateAllStars();
        this._updateItemPortfolioCoinsIds();
        this.loadingTarget.classList.add("!tw-hidden");
        this._trackEvent("add_portfolio_coin", {
          "event_url": window.location.pathname,
          "event_label": "onboarding",
          "onboarding_type": this._getUserOnboardingType(),
        });
      }
    })
  }

  async _fetchPortfolioCoinsInfo() {
    await fetch(`/onboarding/portfolio_coins_info`)
      .then(async response => {
        if (!response.ok) {
          return Promise.reject("Could not fetch user info.");
        }

        const portfolioCoinsInfo = await response.json();
        this.portfolioId = portfolioCoinsInfo.primaryPortfolioId;
        this.portfolioCoinIds = JSON.parse(portfolioCoinsInfo.primaryCoinIds);
        this._newUser = portfolioCoinsInfo.newUser;
      });
  }

  _updateItemPortfolioCoinsIds() {
    let itemId = `item-portfolio-${this.portfolioId}`;
    let element = document.querySelector(`#${itemId}`);
    let portfolioCoinIds = JSON.stringify(this.portfolioCoinIds)

    element.setAttribute("data-portfolio-coin-ids", portfolioCoinIds);
  }

  _updateAllStars() {
    const coinIdStarsMap = {}
    for (const id of this.portfolioCoinIds) {
      coinIdStarsMap[id] = true;
    }

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const starred = !!coinIdStarsMap[coinId];

      star.classList.toggle("far", !starred);
      star.classList.toggle("fas", starred);
      star.classList.toggle("tw-text-yellow-500", starred);
    });
  }

  _removeFromPortfolioCoins(coinId) {
    let coinIdIndex = this.portfolioCoinIds.indexOf(coinId);

    if (coinIdIndex !== -1) {
      this.portfolioCoinIds.splice(coinIdIndex, 1);
    }
  }

  _getUserOnboardingType() {
    return this._newUser ? "new" : "continuous";
  }

  _trackEvent(eventName, eventParams = {}) {
    if (typeof gtag === 'undefined') return;

    gtag("event", eventName, eventParams);
  }
}
